import { render, staticRenderFns } from "./WithdrawRec.vue?vue&type=template&id=5d34a058&scoped=true"
import script from "./WithdrawRec.vue?vue&type=script&lang=js"
export * from "./WithdrawRec.vue?vue&type=script&lang=js"
import style0 from "./WithdrawRec.vue?vue&type=style&index=0&id=5d34a058&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d34a058",
  null
  
)

export default component.exports