<template>
  <div class="managingPeople hp100 bbox " v-loading="loading">
    <!-- 卡片视图 -->
    <!-- <el-card> -->
      <!-- 搜索-->
      <el-row :gutter="20" type="flex" justify="end">
        <!-- <el-col>
                    <el-button plain class="add-column-btn bbox" @click="gotoDetail('')" type="primary">
                       <i class="el-icon-plus fw900 f16"></i> 新增</el-button>
                </el-col> -->
        <el-col :span="10">
          <div class="flex align-center justify-end header-search-box">
            选择提现状态
            <el-select
              placeholder=""
              clearable
              v-model="queryInfo.condition.cashStatus"
              class="header-search-input ml10"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :value="item.value"
                :label="item.name"
                v-show="item.value != null"
              ></el-option>
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-search"
              class="textblue searchBtn"
              @click="search()"
            ></el-button>
          </div>
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table
        :data="tableData"
        border
        fit
        :height="height"
        highlight-current-row
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column label="编号" type="index" width="50">
        </el-table-column>
        <!-- <el-table-column label="省市区" align="center"  prop="memberCode" >
                        <template slot-scope="scope">
                            <span>{{ scope.row.region }}</span>
                        </template>
                    </el-table-column> -->
        <el-table-column label="提现人姓名" align="center" prop="memberCode">
          <template slot-scope="scope">
            <span>{{ scope.row.alipayName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="提现人手机号" align="center" prop="memberCode">
          <template slot-scope="scope">
            <span>{{ scope.row.alipayAccount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="提现金额" align="center" prop="memberName">
          <template slot-scope="scope">
            <span>{{ scope.row.cashAccount }}</span>
          </template>
        </el-table-column>

        <el-table-column label="提现类型" align="center" prop="">
          <template slot-scope="scope">
            <span>{{ judgeType(scope.row.cashType) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付宝账户" align="center" prop="orderPrice">
          <template slot-scope="scope">
            <span>{{ scope.row.alipayAccount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="银行卡用户名" align="center" prop="orderPrice">
          <template slot-scope="scope">
            <span>{{ scope.row.bankCardName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="开户行" align="center" prop="orderPrice">
          <template slot-scope="scope">
            <span>{{ scope.row.bankName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="银行卡号" align="center" prop="orderPrice">
          <template slot-scope="scope">
            <span>{{ scope.row.bankCardCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="提现状态" align="center" prop="">
          <template slot-scope="scope">
            <span>{{ judgeStatus(scope.row.cashStatus) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="失败原因" align="center" prop="">
          <template slot-scope="scope">
            <span>{{ scope.row.failReason }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="提现时间"
          width="150"
          align="center"
          prop="orderPrice"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.createDt">{{
              scope.row.createDt | dataFormat
            }}</span>
            <span v-else>---</span>
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          width="150"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="查看详情"
              placement="bottom"
            >
              <i
                class="el-icon-edit textblue f16"
                @click="gotoDetail(scope.row.id)"
              ></i>
            </el-tooltip>
            <!-- <el-tooltip
                                class="item"
                                effect="dark"
                                content="审核成功"
                                placement="bottom"
                        >
                        <i
                class="el-icon-check textblue f16 ml20"
                @click="changeStatus(scope.row)"
              ></i>
                        
                        </el-tooltip>     
                        <el-tooltip
                                class="item"
                                effect="dark"
                                content="审核失败"
                                placement="bottom"
                        >
                        <i
                class="el-icon-close textblue f16 ml20"
                @click="addRole(scope.row)"
              ></i>
                        
                        </el-tooltip>                        -->
          </template>
        </el-table-column>
      </el-table>
      <div class="footerBox">
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    <!-- </el-card> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          name: "审核中",
          value: 0
        },
        {
          name: "",
          value: null
        },
        {
          name: "审核成功",
          value: 1
        },
        {
          name: "审核失败",
          value: 2
        }
      ],
      height: window.innerHeight - 256, //表格高度
      value: "",
      tableData: [],
      loading: true,
      id: "",
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 10,
        //查询条件
        condition: {
          id: "",
          cashStatus: null
        }
      },
      addForm: {
        failReason: "",
        id: this.id
      },
      userlist: [],
      total: 0,
      // 修改用户
      editDialogVisible: false,
      dialogFormVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false
    };
  },
  created() {
    // this.queryInfo.page=this.$route.query.page;
    //  this.queryInfo.limit=this.$route.query.limit;
    this.getListData();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    //条件查询
    search() {
      // this.queryInfo.condition.id = this.searchInput;
      this.getListData();
    },
    addRole(row) {
      var that = this;
      this.$prompt("请输入审核失败原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(({ value }) => {
        that.$http
          .post("/memberCash/save", {
            id: row.id,
            failReason: value,
            cashStatus: 2
          })
          .then(function(response) {
            if (response.data.code == 200) {
              that.$message({
                message: "保存成功！！！",
                duration: 1500,
                type: "success"
              });
              that.getListData();
            } else {
              that.$message({
                message: res.data.message,
                duration: 1500,
                type: "error"
              });
            }
          });
      });

      // that.$http.post("/memberCash/save",{id:row.id,failReason:that.addForm.failReason})
      // .then(function (response) {

      //   if (response.data.code == 200) {
      //     that.$message({
      //           message: '保存成功！！！',
      //           duration: 1500,
      //           type: 'success'
      //         })
      //   }else {
      //         that.$message({
      //           message: res.data.message,
      //           duration: 1500,
      //           type: 'error'
      //         })
      //         }
      //   });
    },
    //状态改变事件
    changeStatus(row) {
      this.$confirm("此操作将审批成功该提现, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        var that = this;
        that.cashStatus = 1;
        that.$http
          .post("/memberCash/save", { cashStatus: 1, id: row.id })
          .then(function(response) {
            that.$message({
              type: "success",
              message: "处理成功!"
            });
            that.getListData();
          })
          .catch(function(error) {
            //   console.log("---")
            that.$notify.info({
              title: "提示",
              message: "处理失败",
              showClose: true
            });
          });
      });
    },
    judgeStatus(cashStatus) {
      if (cashStatus === "0") {
        return "审核中";
      } else if (cashStatus === "1") {
        return "审核成功";
      } else if (cashStatus === "2") {
        return "审核失败";
      } else {
        return "";
      }
    },
    judgeType(cashType) {
      if (cashType === "1") {
        return "支付宝";
      } else if (cashType === "2") {
        return "微信";
      } else if (cashType === "3") {
        return "银行卡";
      } else {
        return "";
      }
    },

    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },

    // 监听排序
    sortChange() {},
    //跳转到详情
    gotoDetail(id) {
      var that = this;
      this.$router.push({
        path: "/financeMag/withDrawDetails",
        query: {
          id: id,
          page: that.queryInfo.page,
          limit: that.queryInfo.limit
        }
      });
    },

    // 删除用户
    // async del(id) {
    //   const confirmResult = await this.$confirm("确定删除吗?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).catch((err) => err);
    //   if (confirmResult === "confirm") {
    //        var that = this;
    //     that.$http.post("/repairsInfo/delete" ,{"id":id}).then(function (response) {
    //       that.$notify.success({
    //         title: "提示",
    //         message: "删除成功",
    //         showClose: true,
    //       });
    //       that.getListData();
    //     });
    //   }
    // },
    getListData() {
      // 获取列表
      var that = this;
      var data = that.queryInfo;
      //   console.log(data)
      that.$http
        .post("/memberCash/list", that.queryInfo)
        .then(function(response) {
          //   console.log("------")
          // console.log(response);
          if (response.data.code == 200) {
            // console.log("------")
            // console.log(response.data.data.data)
            that.total = response.data.data.count;
            //   that.queryInfo.page=response.data.page
            that.tableData = response.data.data.data;
          }
        });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    }
  }
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}
.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;
    .el-select > .el-input {
      width: 200px;
    }
    // .header-search-box .header-search-input {
    //     width: 325px;
    // }
    input::placeholder {
      font-size: 12px;
    }
    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }
    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
</style>
